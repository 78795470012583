import { createContext, useEffect } from 'react';
import { useLocation, useMatch } from 'react-router-dom';
import { useDeepCompareEffect } from 'ahooks';

import storage from '@utils/Storage';
import useCodeStore, { useCodeStoreActions } from '@store/useCodeStore';
import { isEmpty } from 'lodash';

const CodeContext = createContext({});

function CodeProvider({ children }) {
  const location = useLocation();
  const { code, clear } = useCodeStore((state) => state);

  const isLoginPage = useMatch('/login');

  const { fetchDeliveryStatusCode } = useCodeStoreActions();

  useEffect(() => {
    if (isLoginPage) {
      clear();
    }
  }, [isLoginPage, clear]);

  useDeepCompareEffect(() => {
    if (storage.hasSession() && isEmpty(code)) {
      fetchDeliveryStatusCode();
      // fetchConsultationCategory();
    }
  }, [location, code]);

  return <CodeContext.Provider value={clear}>{children}</CodeContext.Provider>;
}

export { CodeProvider };
