import React from 'react';
import { Form } from 'antd';

function ResultsField({ fetchedInvoiceInfo }) {
  const results = [
    {
      label: '배송상품 목록',
      key: 'deliveryOrderLines',
      render: () => {
        return fetchedInvoiceInfo.deliveryOrderLines.map(
          (deliveryOrderLine, idx) => (
            <React.Fragment
              key={`deliveryOrderLines-${deliveryOrderLine.productName}-${
                deliveryOrderLine.count
              }-${idx + 1}`}
            >
              <span key={`delivery-order-line-${idx}`}>
                {deliveryOrderLine.productName}, &nbsp;{deliveryOrderLine.count}
                개
              </span>
              <br />
            </React.Fragment>
          ),
        );
      },
    },
    {
      label: '주소',
      key: 'address',
      render: () =>
        fetchedInvoiceInfo
          ? `${fetchedInvoiceInfo.address} ${fetchedInvoiceInfo.detailAddress}`
          : '',
    },
    { label: '받는 사람', key: 'recipient' },
  ];

  return (
    <>
      {results.map(({ label, key, render }, idx) => (
        <Form.Item
          className="mt-10"
          label={
            <span className="h-full mt-3 text-5xl text-neutral-700">
              {label}
            </span>
          }
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 24 }}
          colon={false}
          key={`result-field-${key}-${idx + 1}`}
        >
          <span className="ml-2 text-6xl">
            {render ? render() : fetchedInvoiceInfo[key]}
          </span>
        </Form.Item>
      ))}
    </>
  );
}

export default ResultsField;
