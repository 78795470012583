import { useOverlayContext } from '@context/OverlayContext';
import { useMutation, useQuery } from '@tanstack/react-query';

import { logiApi } from '@utils/Api';

/**
 *
 */
export const useGetDeliveryOrderLines = (deliveryOrderId) => {
  return useQuery(
    [`/external/v1/delivery-orders/${deliveryOrderId}/lines`, deliveryOrderId],
    () => logiApi.get(`/external/v1/delivery-orders/${deliveryOrderId}/lines`),
    { enabled: false },
  );
};

export const useGetDeliveryOrders = (params) => {
  return useQuery(
    [`/external/v1/delivery-orders`, params],
    () => logiApi.get(`/external/v1/delivery-orders`, { params }),
    {
      enabled: !!params.startDate && !!params.endDate,
      staleTime: 0,
    },
  );
};

export const useGetDetailByInvoiceNumber = (invoiceNumber) => {
  return useQuery(
    [`/external/v1/delivery-orders/invoice-numbers/${invoiceNumber}`],
    () =>
      logiApi.get(
        `/external/v1/delivery-orders/invoice-numbers/${invoiceNumber}`,
      ),
    {
      enabled: !!invoiceNumber,
    },
  );
};

export const useGetDeliveryOrderStatusHistories = (deliveryOrderId) => {
  return useQuery(
    [`/external/v1/delivery-orders/${deliveryOrderId}/status-histories`],
    () =>
      logiApi.get(
        `/external/v1/delivery-orders/${deliveryOrderId}/status-histories`,
      ),
    {
      enabled: false,
    },
  );
};

export const useDeliveryStart = () => {
  const { openAlert } = useOverlayContext();
  const onSuccess = () => {};

  const onError = () => {
    openAlert('출고 처리 중 오류가 발생하였습니다.');
  };
  const mutation = useMutation(
    (deliveryOrderIds) =>
      logiApi.post('/external/v1/delivery-orders/start', {
        deliveryOrderIds,
      }),
    { onSuccess, onError },
  );

  const requestDeliveryStart = (deliveryOrderIds) => {
    mutation.mutate(deliveryOrderIds);
  };

  return requestDeliveryStart;
};

export const usePrintInvoiceNumbers = () => {
  const { openAlert } = useOverlayContext();
  const onSuccess = () => {};

  const onError = () => {
    openAlert('송장 출력을 진행할 수 없습니다.');
  };
  const mutation = useMutation(
    (deliveryOrderIds) =>
      logiApi.post('/external/v1/delivery-orders/invoice-numbers', {
        deliveryOrderIds,
      }),
    { onSuccess, onError },
  );

  const requestPrintInvoiceNumbers = async (deliveryOrderIds) => {
    const data = await mutation.mutateAsync(deliveryOrderIds);
    return data;
  };

  return requestPrintInvoiceNumbers;
};

export const useInstructions = () => {
  const { openAlert } = useOverlayContext();
  const onSuccess = () => {};

  const onError = () => {
    openAlert('출고지시서 출력을 진행할 수 없습니다.');
  };
  const mutation = useMutation(
    (deliveryOrderIds) =>
      logiApi.post('/external/v1/delivery-orders/instructions', {
        deliveryOrderIds,
      }),
    { onSuccess, onError },
  );

  const requestInstructions = async (deliveryOrderIds) => {
    const data = await mutation.mutateAsync(deliveryOrderIds);
    return data;
  };

  return requestInstructions;
};

export const useCompleteDeliveryOrders = () => {
  const { openAlert } = useOverlayContext();
  const onSuccess = () => {};

  const onError = () => {
    openAlert('출고확정에 실패했습니다.');
  };
  const mutation = useMutation(
    (deliveryOrderIds) =>
      logiApi.post('/external/v1/delivery-orders/complete', {
        deliveryOrderIds,
      }),
    { onSuccess, onError },
  );

  const requestCompleteDeliveryOrders = async (deliveryOrderIds) => {
    const data = await mutation.mutateAsync(deliveryOrderIds);
    return data;
  };

  return requestCompleteDeliveryOrders;
};

export const usePODRetryCompress = (reFetch) => {
  const { openAlert } = useOverlayContext();
  const onSuccess = () => {
    reFetch();
  };

  const onError = () => {
    openAlert('재압축에 실패했습니다.');
  };
  const mutation = useMutation(
    (deliveryOrderId) =>
      logiApi.post(
        `/external/v1/delivery-orders/${deliveryOrderId}/retry-compress`,
      ),
    { onSuccess, onError },
  );

  const requestRetryCompress = (deliveryOrderId) => {
    mutation.mutate(deliveryOrderId);
  };

  return requestRetryCompress;
};
