import { Modal, Button, Row, Col, message } from 'antd';

import { useOverlayContext } from '@context/OverlayContext';
import { onCopyToClipboard } from '@helper/uiHelper';
import ModalContainer from './base/ModalContainer';

function ModalPrintInvoice({ url }) {
  const { closeModal } = useOverlayContext();
  const handleCopyURL = () => {
    onCopyToClipboard(url);
    message.success('URL이 클립보드에 복사되었습니다.');
  };
  return (
    <ModalContainer>
      <Modal
        title="송장 출력"
        footer={null}
        maskClosable={false}
        onCancel={closeModal}
        open
      >
        <Row align="middle" justify="center" className="mt-5">
          <Col className="text-lg">
            URL을 복사해서 익스플로러에서 실행하세요.
          </Col>
        </Row>
        <Row align="middle" justify="center">
          <Col>
            <div className="border-[2px] border-solid p-5 m-5 font-semibold text-lg underline">
              {url}
            </div>
          </Col>
        </Row>
        <Row align="middle" justify="center">
          <Col>
            <Button type="primary" onClick={handleCopyURL}>
              복사하기
            </Button>
          </Col>
        </Row>
      </Modal>
    </ModalContainer>
  );
}

export default ModalPrintInvoice;
