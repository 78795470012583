import { useGetConsultationCategory, useGetDeliveryStatus } from '@api/code';
import { create } from 'zustand';
import produce from 'immer';

export const CODE_KEY = {
  POD_DELIVERY_STATUS: 'podDeliveryStatus',
  ISD_DELIVERY_STATUS: 'isdDeliveryStatus',
  CONSULTATION_CATEGORY: 'consultationCategory',
};

const initialValue = {};

const useCodeStore = create((set) => ({
  code: initialValue,
  clear: () => set(initialValue),
  setCode: (code) =>
    set(
      produce((state) => {
        Object.keys(code).forEach((key) => {
          state.code[key] = code[key];
        });
      }),
    ),
}));

export const useCodeStoreActions = () => {
  const setCode = useCodeStore((state) => state.setCode);

  const { refetch: refetchDeliveryStatus } = useGetDeliveryStatus();
  // const { refetch: refetchConsultationCategory } = useGetConsultationCategory();

  const fetchDeliveryStatusCode = () => {
    (async () => {
      try {
        const { data } = await refetchDeliveryStatus();
        const deliveryStatus = JSON.parse(data);

        const podDeliveryStatus = {};
        const isdDeliveryStatus = {};

        Object.keys(deliveryStatus).forEach((key) => {
          if (key.includes('POD')) {
            podDeliveryStatus[key] = {
              label: deliveryStatus[key],
              value: key,
            };
          }
          if (key.includes('ISD')) {
            isdDeliveryStatus[key] = {
              label: deliveryStatus[key],
              value: key,
            };
          }
        });

        setCode({
          [CODE_KEY.POD_DELIVERY_STATUS]: podDeliveryStatus,
          [CODE_KEY.ISD_DELIVERY_STATUS]: isdDeliveryStatus,
        });
      } catch (error) {
        console.warn(error);
      }
    })();
  };

  return {
    fetchDeliveryStatusCode,
  };
};

export default useCodeStore;
