import { useMemo } from 'react';
import dayjs from 'dayjs';
import { Modal, Button, Descriptions, Table } from 'antd';

import { useOverlayContext } from '@context/OverlayContext';
import { TagDeliveryStatus } from '@components/common';
import { toDateFormat } from '@helper/formatHelper';
import ModalContainer from './base/ModalContainer';

function ModalDeliveryStatusHistory({ data }) {
  const { closeModal } = useOverlayContext();

  const histories = useMemo(() => {
    if (!data) return [];
    return data.map((history, idx) => ({
      ...history,
      key: `${history.deliveryOrderId}-${dayjs(history.createdDate).format(
        'YYYYMMDD',
      )}-${idx + 1}`,
    }));
  }, [data]);

  return (
    <ModalContainer>
      <Modal
        open
        onCancel={closeModal}
        title="배송상태 변경이력"
        footer={[
          <Button
            type="default"
            onClick={closeModal}
            key="deliveryStatusHistory-close-btn"
          >
            닫기
          </Button>,
        ]}
        width="50%"
        closeIcon={<></>}
      >
        <Descriptions bordered className="my-5">
          <Descriptions.Item
            label="배송요청번호(ID)"
            labelStyle={{ width: '25%' }}
          >
            {histories?.[0].deliveryOrderId}
          </Descriptions.Item>
        </Descriptions>
        <Table
          dataSource={histories ?? []}
          pagination={false}
          columns={[
            {
              title: '상태',
              dataIndex: 'status',
              render: (_, { status }) => (
                <TagDeliveryStatus deliveryStatus={status} />
              ),
            },
            {
              title: '일자',
              dataIndex: 'createdDate',
              render: (createdDate) => toDateFormat(createdDate),
            },
            { title: '작업자', dataIndex: 'processor' },
            { title: '사유', dataIndex: 'reason', width: 250 },
          ]}
          size="middle"
        />
      </Modal>
    </ModalContainer>
  );
}

export default ModalDeliveryStatusHistory;
