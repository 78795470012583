import withPrivate from '@hoc/withPrivate';
import deliveryConfig from './private/deliveryConfig';
import warehouseConfig from './private/warehouseConfig';
import inventoryConfig from './private/inventoryConfig';

const privateRouteConfig = [
  ...deliveryConfig,
  ...warehouseConfig,
  ...inventoryConfig,
].map((item) => {
  const Component = withPrivate(item.element);
  // const Component = item.element;

  return {
    ...item,
    element: <Component />,
  };
});

export default privateRouteConfig;
