/* eslint-disable import/no-extraneous-dependencies */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { logiApi } from '@utils/Api';
import { useOverlayContext } from '@context/OverlayContext';
import storage from '@utils/Storage';

export const useToken = () => {
  const { openAlert } = useOverlayContext();

  const onError = () => {
    openAlert('아이디 혹은 비밀번호가 틀렸습니다');
  };

  const mutation = useMutation((data) =>
    logiApi.post('/external/p2p/auth/send-talk', data),
  );

  const requestToken = ({ userId, userPw }, { onSuccess }) => {
    mutation.mutate(
      { userId, userPw },
      {
        onSuccess: () => {
          onSuccess?.();
        },
        onError,
      },
    );
  };

  return requestToken;
};

export const useLogin = () => {
  const { openAlert } = useOverlayContext();

  const onError = () => {
    openAlert('입력하신 정보가 잘못되었습니다.');
  };

  const mutation = useMutation((data) =>
    logiApi.post('/external/admin/loginProcess', data),
  );

  const requestLogin = ({ userId, userPw, authKey }, { onSuccess }) => {
    mutation.mutate(
      { userId, userPw, authKey },
      {
        onSuccess: (result) => {
          const { accessToken, refreshToken } = result;
          storage.setToken(accessToken, refreshToken);

          onSuccess?.();
        },
        onError,
      },
    );
  };

  return requestLogin;
};
