import { logiApi } from '@utils/Api';

export const uploadFiles = (fileList, url) =>
  new Promise((resolve, reject) => {
    if (fileList.length === 0) resolve([]);

    const formData = new FormData();

    // eslint-disable-next-line no-restricted-syntax
    for (const file of fileList) {
      formData.append('files', file);
    }

    logiApi
      .post(url, {
        data: formData,
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const urlToBlob = (url) =>
  new Promise((resolve, reject) => {
    if (!url) resolve(undefined);
    logiApi
      .get(url, {
        responseType: 'blob',
        headers: {
          accept: 'application/octet-stream',
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
