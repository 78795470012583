import React from 'react';
import { Button } from 'antd';

import { useInstructions } from '@api/delivery-order';
import { useOverlayContext } from '@context/OverlayContext';
import { ModalPrintPickingListPaper } from '@components/overlay';

function PrintPickingListPaperButton({ deliveryOrderIds }) {
  const requestInstructions = useInstructions();
  const { openModal } = useOverlayContext();

  const onClickButton = async () => {
    const fetchedInstructions = await requestInstructions(deliveryOrderIds);
    openModal(ModalPrintPickingListPaper, {
      deliveryOrderInstructions: fetchedInstructions?.deliveryOrderInstructions,
      productInstructions: fetchedInstructions?.productInstructions,
    });
  };
  return (
    <Button
      type="primary"
      onClick={onClickButton}
      disabled={!deliveryOrderIds.length}
    >
      출고지시서 출력
    </Button>
  );
}

export default PrintPickingListPaperButton;
