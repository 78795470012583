import { useNavigate, useRouteError } from 'react-router-dom';
import { Button } from 'antd';

function RuntimeErrorPage() {
  const navigate = useNavigate();
  const error = useRouteError();

  return (
    <div className="w-screen h-screen flex flex-col justify-center items-center ">
      <h1>Runtime Error</h1>
      <h3>
        시스템 에러가 발생하여 페이지를 표시할 수 없습니다.
        <br />
        관리자에게 문의하시거나 잠시 후 다시 시도하세요.
      </h3>
      <Button onClick={() => navigate(-1)} type="primary" size="large">
        돌아가기
      </Button>
    </div>
  );
}

export default RuntimeErrorPage;
