import { Search } from '@components/ui';
import React from 'react';

const FormSearchGroup = ({ id, forms }) => {
  return forms.map((group, idx1) => (
    <div
      className="flex flex-row flew-wrap w-full"
      key={`${id}-search-group-${idx1 + 1}`}
    >
      {group.map((el, idx2) => (
        <Search.Item key={`${id}-search-elem-${idx1 + 1}-${idx2 + 1}`}>
          {el}
        </Search.Item>
      ))}
    </div>
  ));
};

export default FormSearchGroup;
