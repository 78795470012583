import React from 'react';
import { message, Button } from 'antd';

import { useOverlayContext } from '@context/OverlayContext';
import { usePrintInvoiceNumbers } from '@api/delivery-order';
import { ModalPrintInvoice } from '@components/overlay';

function PrintInvoiceButton({ deliveryOrderIds }) {
  const requestPrintInvoiceNumbers = usePrintInvoiceNumbers();
  const { openModal } = useOverlayContext();

  const onOpenPrintInvoiceModal = async () => {
    try {
      const { printUrl } = await requestPrintInvoiceNumbers(deliveryOrderIds);
      openModal(ModalPrintInvoice, {
        url: printUrl,
      });
    } catch (err) {
      console.error(err);
      return message.error(
        err?.response?.data?.message || '송장 출력을 진행할 수 없습니다.',
      );
    }
  };

  return (
    <Button
      type="primary"
      onClick={onOpenPrintInvoiceModal}
      disabled={!deliveryOrderIds.length}
    >
      송장 출력
    </Button>
  );
}

export default PrintInvoiceButton;
