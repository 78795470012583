import React from 'react';
import { Tooltip, message } from 'antd';

import { onCopyToClipboard } from '@helper/uiHelper';

function LongColumnItem({ value, render }) {
  const handleCopy = () => {
    onCopyToClipboard(value);
    message.success('클립보드에 복사 되었습니다');
  };
  return (
    <Tooltip
      title={
        <div
          onClick={handleCopy}
          className="hover:cursor-[pointer]"
          role="presentation"
        >
          {value}
        </div>
      }
      placement="topLeft"
    >
      <div className="text-ellipsis overflow-hidden">
        {render ? render() : value}
      </div>
    </Tooltip>
  );
}
export default LongColumnItem;
