import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';

function NotFoundPage() {
  const navigate = useNavigate();
  return (
    <div className="w-screen h-screen flex flex-col justify-center items-center ">
      <h1>404</h1>
      <h3>Not Found</h3>
      <h3>페이지가 존재하지 않습니다</h3>
      <Button onClick={() => navigate(-1)} type="primary" size="large">
        돌아가기
      </Button>
    </div>
  );
}

export default NotFoundPage;
