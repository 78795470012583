import dayjs from 'dayjs';

export const API_DATE_FORMAT = 'YYYY-MM-DD';

export const toPhoneNumberFormat = (phoneNumber) =>
  phoneNumber?.includes('-')
    ? phoneNumber
    : phoneNumber?.replace(
        /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
        '$1-$2-$3',
      );

export const toDateFormat = (date, format = 'YYYY-MM-DD HH:mm:ss') => {
  if (!date) {
    return '';
  }
  return dayjs(date).format(format);
};

export const toMoneyFormat = (value) =>
  value ? `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원` : '';
