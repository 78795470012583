import { useState } from 'react';
import { omit } from 'lodash';
import { Col, Divider, Form, Row, Space, Table } from 'antd';

import { FormDatePicker, FormInput, FormSearchGroup } from '@components/form';
import { Search } from '@components/ui';
import { LongColumnItem, renderColumn } from '@components/common';
import useDeliveryOrder from '@hooks/delivery-order/useDeliveryOrder';
import { API_DATE_FORMAT, toDateFormat } from '@helper/formatHelper';
import { PrintPickingListPaperButton } from '@feature/delivery-order';
import { INVOICE_STATUS, ISD_TYPE } from '@constants/code';

function InvoicePage() {
  const [form] = Form.useForm();
  const { deliverOrders, handleSetPayload, handleSetPage } = useDeliveryOrder({
    type: ISD_TYPE,
    status: INVOICE_STATUS,
  });

  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const handleSubmit = (formValue) => {
    const {
      deliveryOrderDate: [startDate, endDate],
    } = formValue;
    const invoiceStartDate = formValue.invoiceDate && formValue.invoiceDate[0];
    const invoiceEndDate = formValue.invoiceDate && formValue.invoiceDate[1];

    const payload = {
      ...omit(formValue, ['deliveryOrderDate', 'invoiceDate']),
      contact: formValue.contact,
      startDate: toDateFormat(startDate.startOf('day'), API_DATE_FORMAT),
      endDate: toDateFormat(endDate.endOf('day'), API_DATE_FORMAT),
      invoiceStartDate:
        invoiceStartDate &&
        toDateFormat(invoiceStartDate.startOf('day'), API_DATE_FORMAT),
      invoiceEndDate:
        invoiceEndDate &&
        toDateFormat(invoiceEndDate.endOf('day'), API_DATE_FORMAT),
    };
    if (!payload.orderNo) delete payload.orderNo;
    if (!payload.recipient) delete payload.recipient;
    if (!payload.contact) delete payload.contact;
    if (!invoiceStartDate) payload.invoiceStartDate = undefined;
    if (!invoiceEndDate) payload.invoiceEndDate = undefined;
    handleSetPayload({
      ...payload,
      page: 1,
    });
  };

  const INVOICE_SEARCH_FORM = [
    [
      <FormInput
        placeholder="주문번호를 입력하세요"
        label="주문번호"
        name="search"
      />,
      <FormInput
        placeholder="연락처를 입력하세요"
        label="연락처"
        name="contact"
      />,
      <FormInput
        placeholder="받는 사람을 입력하세요"
        label="받는 사람"
        name="recipient"
      />,
    ],
    [
      <Search.Item>
        <FormDatePicker
          label="배송주문일시"
          name="deliveryOrderDate"
          required
        />
      </Search.Item>,
      <Search.Item>
        <FormDatePicker label="송장출력일시" name="invoiceDate" />
      </Search.Item>,
    ],
  ];

  const columns = [
    {
      title: '배송요청번호',
      dataIndex: 'id',
      render: renderColumn.deliveryOrderId,
      ellipsis: true,
      width: 280,
    },
    { title: '주문번호', dataIndex: 'orderNo', width: 200 },
    { title: '송장번호', dataIndex: 'invoiceNumber', width: 120 },
    { title: '받는 사람', dataIndex: 'recipient', width: 120 },
    { title: '연락처', dataIndex: 'contact', width: 150 },
    {
      title: '주소',
      dataIndex: 'address',
      render: (_, row) => (
        <LongColumnItem value={renderColumn.address(_, row)} />
      ),
    },
    {
      title: '배송주문일시',
      dataIndex: 'createdDate',
      render: (createdDate) => toDateFormat(createdDate),
      width: 170,
    },
    {
      title: '송장출력일시',
      dataIndex: 'modifiedDate',
      render: (createdDate) => toDateFormat(createdDate),
      width: 170,
    },
  ];

  return (
    <Form form={form} onFinish={handleSubmit}>
      <Search>
        <FormSearchGroup id="isd-delivery" forms={INVOICE_SEARCH_FORM} />
      </Search>
      <Divider className="mb-10" />
      <Row justify="space-between" align="bottom">
        <Col className="font-semibold">
          총&nbsp;
          <span className="text-secondary">{deliverOrders?.total || 0}</span>개
        </Col>
        <Col>
          <Space size="small">
            <PrintPickingListPaperButton deliveryOrderIds={selectedRowIds} />
          </Space>
        </Col>
      </Row>
      <Table
        className="pt-3 whitespace-pre"
        columns={columns}
        dataSource={deliverOrders?.list || []}
        rowSelection={{
          selectedRowKeys: selectedRowIds,
          onChange: setSelectedRowIds,
        }}
        pagination={{
          showSizeChanger: false,
          total: deliverOrders?.total || 0,
          current: deliverOrders?.pageNum || 1,
          onChange: (current) => handleSetPage(current),
        }}
      />
    </Form>
  );
}
export default InvoicePage;
