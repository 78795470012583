import { Button, Form, Input, message } from 'antd';
import React, { useState } from 'react';

import {
  useCompleteDeliveryOrders,
  useGetDetailByInvoiceNumber,
} from '@api/delivery-order';

import ResultsField from './components/ResultField';

function ConfirmPage() {
  const [form] = Form.useForm();
  const [invoiceInfo, setInvoiceInfo] = useState(null);

  const { data: fetchedInvoiceInfo } = useGetDetailByInvoiceNumber(invoiceInfo);
  const requestCompleteDeliveryOrders = useCompleteDeliveryOrders();

  const resetFields = () => {
    setInvoiceInfo(null);
    form.resetFields(['invoiceNumber', 'complete']);
  };

  const completeDeliveryOrders = async () => {
    try {
      await requestCompleteDeliveryOrders([fetchedInvoiceInfo.id]);
      resetFields();
      return message.success('출고 확정이 정상처리 되었습니다.');
    } catch (err) {
      console.error(err?.response ?? err);
      message.error(err?.response?.data?.message ?? '출고확정에 실패했습니다.');
      throw err;
    }
  };

  const invoiceNumberInputNormalizer = (value, prevValue) => {
    if (value === prevValue) return value;
    return value.replace(/[^a-zA-Z0-9]/g, '');
  };

  const onPressEnter = (e) => {
    const { id } = e.target;
    const targetValue = form.getFieldValue(id);
    if (id === 'invoiceNumber') {
      setInvoiceInfo(targetValue);
      form.resetFields(['complete']);
    } else if (id === 'complete') {
      if (targetValue === 'COMPLETE') {
        completeDeliveryOrders();
        resetFields();
      } else if (targetValue === 'RESET') {
        resetFields();
      }
    }
  };

  return (
    <Form form={form}>
      <div className="w-full flex justify-end">
        <Button className="bg-secondary text-white mr-10" onClick={resetFields}>
          Reset
        </Button>
      </div>
      <Form.Item
        className="mt-10"
        label={
          <span className="h-full mt-3 text-5xl text-neutral-700">
            송장번호
          </span>
        }
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 24 }}
        colon={false}
        rules={[
          { required: true, message: '숫자나 대문자 영어만 가능합니다.' },
        ]}
        normalize={invoiceNumberInputNormalizer}
        name="invoiceNumber"
        key="invoiceNumber"
      >
        <Input className="text-5xl" autoFocus onPressEnter={onPressEnter} />
      </Form.Item>
      {!!fetchedInvoiceInfo && (
        <>
          <ResultsField fetchedInvoiceInfo={fetchedInvoiceInfo} />
          <Form.Item
            className="mt-10"
            label={
              <span className="h-full mt-3 text-5xl text-neutral-700">
                완료
              </span>
            }
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 24 }}
            colon={false}
            rules={[
              {
                required: true,
                message: '필수 입력입니다.',
                pattern: /\bCOMPLETE\b/,
              },
            ]}
            name="complete"
            key="complete"
          >
            <Input className="text-5xl" onPressEnter={onPressEnter} />
          </Form.Item>
        </>
      )}
    </Form>
  );
}

export default ConfirmPage;
