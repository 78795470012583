import { useEffect, useState, useRef } from 'react';

import { createPortal } from 'react-dom';

const OVERLAY_ROOT_ID = 'overlay-root';

function OverlayPortal({ children }) {
  const ref = useRef(null);

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    ref.current = document.getElementById(OVERLAY_ROOT_ID);

    setMounted(true);

    return () => {
      ref.current = null;
      setMounted(false);
    };
  }, []);

  return mounted ? createPortal(children, ref.current) : null;
}

export default OverlayPortal;
