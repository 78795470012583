import { useEffect, useMemo, useState } from 'react';
import { Layout, Menu } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { InboxOutlined, ToolOutlined } from '@ant-design/icons';

import deliveryConfig from '@config/private/deliveryConfig';
import warehouseConfig from '@config/private/warehouseConfig';
import inventoryConfig from '@config/private/inventoryConfig';

function Lnb() {
  const navigate = useNavigate();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [openKeys, setOpenKeys] = useState(['delivery']);
  const [selectKey, setSelectKey] = useState('isd');

  const deliveryItems = deliveryConfig.map((item) => item.option);
  const warehouseItems = warehouseConfig.map((item) => item.option);
  const inventoryItems = inventoryConfig.map((item) => item.option);

  const lnbItems = [
    {
      label: '주문배송',
      key: 'delivery',
      children: deliveryItems,
      icon: <InboxOutlined />,
    },
    {
      label: '작업장',
      key: 'warehouse',
      children: warehouseItems,
      icon: <ToolOutlined />,
    },
    // { label: '재고', key: 'inventory', children: inventoryItems },
  ];

  const handleNavigate = ({ key }) => {
    navigate(`/${key}`);
    setSelectKey(key);
  };

  const handleOpenChange = (keys) => setOpenKeys(keys);

  const currentOpenKey = useMemo(() => {
    const key = location.pathname.substring(1);
    let openKey = null;
    if (deliveryItems.some((item) => item.key === key)) openKey = 'delivery';
    if (warehouseItems.some((item) => item.key === key)) openKey = 'warehouse';
    if (inventoryItems.some((item) => item.key === key)) openKey = 'inventory';
    return openKey;
  }, [deliveryItems, inventoryItems, location.pathname, warehouseItems]);

  useEffect(() => {
    const key = location.pathname.substring(1);
    if (currentOpenKey)
      setOpenKeys((prevOpenKeys) => [...prevOpenKeys, currentOpenKey]);
    setSelectKey(key);
  }, [currentOpenKey, location.pathname]);

  return (
    <>
      <Layout.Sider
        theme="light"
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <Menu
          theme="light"
          items={lnbItems}
          defaultSelectedKeys={['isd']}
          defaultOpenKeys={['delivery']}
          selectedKeys={selectKey}
          openKeys={openKeys}
          onClick={handleNavigate}
          onOpenChange={handleOpenChange}
          mode="inline"
        />
      </Layout.Sider>
    </>
  );
}

export default Lnb;
