import { useQuery } from '@tanstack/react-query';

import { api, logiApi, casApi } from '@utils/Api';

/**
 * 배송 상태 코드 조회
 */
export const useGetDeliveryStatus = () => {
  return useQuery(
    ['/external/v1/common/groups/DELIVERY_ORDER/subGroups/STATUS'],
    () =>
      logiApi.get('/external/v1/common/groups/DELIVERY_ORDER/subGroups/STATUS'),
    { enabled: false },
  );
};
