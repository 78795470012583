import { useCallback, useMemo, useState } from 'react';

import { useGetDeliveryOrders } from '@api/delivery-order';
import dayjs from 'dayjs';

function useDeliveryOrder(defaultParams) {
  const [payload, setPayload] = useState({
    page: 1,
    size: 10,
    customerId: undefined,
    customerName: undefined,
    customerPhone: undefined,
    startDate: '',
    endDate: '',

    contact: undefined,
    id: undefined,
    invoiceEndDate: undefined,
    invoiceNumber: undefined,
    invoiceStartDate: undefined,
    orderNo: undefined,
    recipient: undefined,
    sender: undefined,
    status: undefined,
    ...defaultParams,
  });

  const { data, refetch: refetchDeliveryOrders } =
    useGetDeliveryOrders(payload);

  const handleSetPage = useCallback(
    (page) => {
      setPayload({ ...payload, page });
    },
    [payload],
  );

  const handleSetPayload = useCallback(
    (value) => {
      const payloadProps = { ...value };
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, v] of Object.entries(payloadProps)) {
        if (v === '') payloadProps[key] = undefined;
      }
      setPayload({ ...payload, ...payloadProps });
    },
    [payload],
  );

  const handleSearch = useCallback(() => {
    refetchDeliveryOrders();
  }, [refetchDeliveryOrders]);

  const deliverOrders = useMemo(() => {
    return {
      ...data,
      list:
        data?.list.map((deliverOrder) => ({
          ...deliverOrder,
          diffDay: dayjs().diff(deliverOrder.createdDate, 'day'),
          key: deliverOrder.id,
        })) || [],
    };
  }, [data]);

  return {
    deliverOrders,
    handleSetPage,
    handleSetPayload,
    handleSearch,
    refetchDeliveryOrders,
  };
}

export default useDeliveryOrder;
