import { omit } from 'lodash';
import { Button, Col, Divider, Form, Row, Select, Table, Tag } from 'antd';

import {
  FormDatePicker,
  FormInput,
  FormItemForSelect,
  FormSearchGroup,
} from '@components/form';
import { Search } from '@components/ui';
import useDeliveryOrder from '@hooks/delivery-order/useDeliveryOrder';
import { API_DATE_FORMAT, toDateFormat } from '@helper/formatHelper';
import useCodeStore, { CODE_KEY } from '@store/useCodeStore';
import {
  TagDeliveryStatus,
  LongColumnItem,
  renderColumn,
} from '@components/common';
import { POD_TYPE } from '@constants/code';
import {
  DeliveryStatusHistoryButton,
  PODRetryCompressButton,
  PODPrintInfoDownloadButton,
} from '@feature/delivery-order';
import { useCallback } from 'react';

function PODDeliveryPage() {
  const [form] = Form.useForm();
  const {
    deliverOrders,
    handleSetPayload,
    handleSetPage,
    refetchDeliveryOrders,
  } = useDeliveryOrder({
    type: POD_TYPE,
  });
  const code = useCodeStore((state) => state.code);

  const handleSubmit = (formValue) => {
    const {
      deliveryOrderDate: [startDate, endDate],
    } = formValue;
    const payload = {
      ...omit(formValue, ['deliveryOrderDate']),
      contact: formValue.contact,
      startDate: toDateFormat(startDate.startOf('day'), API_DATE_FORMAT),
      endDate: toDateFormat(endDate.endOf('day'), API_DATE_FORMAT),
    };

    handleSetPayload({
      ...payload,
      page: 1,
    });
  };

  const POD_SEARCH_FORM = [
    [
      <FormInput
        label="주문번호"
        name="orderNo"
        placeholder="주문번호를 입력하세요"
      />,
      <FormInput
        label="배송요청번호"
        name="id"
        placeholder="배송요청번호를 입력하세요"
      />,
    ],
    [
      <FormInput
        label="연락처"
        name="contact"
        placeholder="연락처를 입력하세요"
      />,
      <FormInput
        label="받는 사람"
        name="recipient"
        placeholder="받는 사람을 입력하세요"
      />,
    ],
    [
      <FormItemForSelect label="배송 상태" name="status" initialValue="">
        <Select
          options={
            code[CODE_KEY.POD_DELIVERY_STATUS] && [
              { label: '전체', value: '' },
              ...Object.values(code[CODE_KEY.POD_DELIVERY_STATUS]),
            ]
          }
        />
      </FormItemForSelect>,
      <Search.Item>
        <FormDatePicker
          label="배송주문일시"
          name="deliveryOrderDate"
          required
        />
      </Search.Item>,
    ],
  ];

  const columns = [
    {
      title: '배송요청번호',
      dataIndex: 'id',
      render: renderColumn.deliveryOrderId,
      ellipsis: true,
      width: 240,
    },
    { title: '주문번호', dataIndex: 'orderNo', width: 160 },
    { title: '인쇄소명', dataIndex: 'printShop', width: 100 },
    {
      title: '배송상태',
      dataIndex: 'status',
      render: (status) => <TagDeliveryStatus deliveryStatus={status} />,
      width: 120,
    },
    { title: '받는 사람', dataIndex: 'recipient', width: 120 },
    { title: '연락처', dataIndex: 'contact', width: 130 },
    {
      title: '주소',
      dataIndex: 'address',
      render: (_, row) => (
        <LongColumnItem value={renderColumn.address(_, row)} />
      ),
      width: 200,
    },
    {
      title: '배송주문일시',
      dataIndex: 'createdDate',
      render: (createdDate) => toDateFormat(createdDate),
      width: 170,
    },
    {
      title: '배송상태 변경이력',
      dataIndex: 'statusHistory',
      render: (_, { id }) => (
        <DeliveryStatusHistoryButton deliveryOrderId={id} />
      ),
      width: 120,
    },
    {
      title: '압축상태',
      dataIndex: 'compressStatus',
      render: (compressStatus, { id }) => {
        switch (compressStatus) {
          case 'SUCCESS':
            return <Tag color="success">압축 성공</Tag>;
          case 'REQUEST':
            return <Tag color="processing">압축 진행중</Tag>;
          default:
            return (
              <PODRetryCompressButton
                reFetch={refetchDeliveryOrders}
                deliveryOrderId={id}
              />
            );
        }
      },
      width: 100,
    },
    {
      title: '인쇄정보',
      dataIndex: 'compressStatus',
      render: (_, { id, status, diffDay }) => (
        <>
          {status !== 'POD01999' && (
            <PODPrintInfoDownloadButton
              disabled={diffDay >= 180}
              deliveryOrderId={id}
            />
          )}
        </>
      ),
      width: 120,
    },
  ];

  const handleRefetch = useCallback(async () => {
    await refetchDeliveryOrders();
  }, [refetchDeliveryOrders]);

  return (
    <Form form={form} onFinish={handleSubmit}>
      <Search>
        <FormSearchGroup id="isd-delivery" forms={POD_SEARCH_FORM} />
      </Search>
      <Divider className="mb-10" />
      <Row justify="space-between" align="bottom">
        <Col className="font-semibold">
          총&nbsp;
          <span className="text-secondary">{deliverOrders?.total || 0}</span>개
        </Col>
      </Row>
      <Row className="pt-3">
        <Col>
          <Button
            className="text-xs bg-secondary text-white border-secondary"
            onClick={handleRefetch}
            size="small"
          >
            목록 새로고침
          </Button>
        </Col>
      </Row>
      <Table
        className="pt-3 whitespace-pre"
        columns={columns}
        dataSource={deliverOrders?.list || []}
        pagination={{
          showSizeChanger: false,
          total: deliverOrders?.total || 0,
          current: deliverOrders?.pageNum || 1,
          onChange: (current) => handleSetPage(current),
        }}
      />
    </Form>
  );
}

export default PODDeliveryPage;
