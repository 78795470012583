import { useState } from 'react';
import { omit } from 'lodash';
import { Col, Divider, Form, Row, Space, Table } from 'antd';

import { FormDatePicker, FormInput, FormSearchGroup } from '@components/form';
import { Search } from '@components/ui';
import { API_DATE_FORMAT, toDateFormat } from '@helper/formatHelper';
import useDeliveryOrder from '@hooks/delivery-order/useDeliveryOrder';
import { LongColumnItem, renderColumn } from '@components/common';
import { StartDeliveryProcessButton } from '@feature/delivery-order';
import { ISD_DELIVERY_STATUS, ISD_TYPE } from '@constants/code';

function ISDDeliveryPage() {
  const [form] = Form.useForm();
  const {
    deliverOrders,
    handleSetPayload,
    refetchDeliveryOrders,
    handleSetPage,
  } = useDeliveryOrder({
    type: ISD_TYPE,
    status: ISD_DELIVERY_STATUS,
  });

  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const handleSubmit = (formValue) => {
    const {
      deliveryOrderDate: [startDate, endDate],
    } = formValue;
    const payload = {
      ...omit(formValue, ['deliveryOrderDate']),
      contact: formValue.contact,
      startDate: toDateFormat(startDate.startOf('day'), API_DATE_FORMAT),
      endDate: toDateFormat(endDate.endOf('day'), API_DATE_FORMAT),
    };
    if (!payload.orderNo) delete payload.orderNo;
    if (!payload.recipient) delete payload.recipient;
    if (!payload.contact) delete payload.contact;
    handleSetPayload({
      ...payload,
      page: 1,
    });
  };

  const ISD_SEARCH_FORM = [
    [
      <FormInput
        placeholder="주문번호를 입력하세요"
        label="주문번호"
        name="orderNo"
      />,
      <FormInput
        placeholder="연락처를 입력하세요"
        label="연락처"
        name="contact"
      />,
    ],
    [
      <FormInput
        placeholder="받는 사람을 입력하세요"
        label="받는 사람"
        name="recipient"
      />,
      <Search.Item>
        <FormDatePicker
          label="배송주문일시"
          name="deliveryOrderDate"
          required
        />
      </Search.Item>,
    ],
  ];

  const columns = [
    {
      title: '배송요청번호',
      dataIndex: 'id',
      render: renderColumn.deliveryOrderId,
      ellipsis: true,
      width: 280,
    },
    { title: '주문번호', dataIndex: 'orderNo', width: 200 },
    { title: '받는 사람', dataIndex: 'recipient', width: 120 },
    { title: '연락처', dataIndex: 'contact', width: 150 },
    {
      title: '주소',
      dataIndex: 'address',
      render: (_, row) => (
        <LongColumnItem value={renderColumn.address(_, row)} />
      ),
    },
    {
      title: '배송주문일시',
      dataIndex: 'createdDate',
      render: (createdDate) => toDateFormat(createdDate),
      width: 170,
    },
  ];

  return (
    <Form form={form} onFinish={handleSubmit}>
      <Search>
        <FormSearchGroup id="isd-delivery" forms={ISD_SEARCH_FORM} />
      </Search>
      <Divider className="mb-10" />
      <Row justify="space-between" align="bottom">
        <Col className="font-semibold">
          총&nbsp;
          <span className="text-secondary">{deliverOrders?.total || 0}</span>개
        </Col>
        <Col>
          <Space size="small">
            <StartDeliveryProcessButton
              reFetch={refetchDeliveryOrders}
              deliveryOrderIds={selectedRowIds}
            />
          </Space>
        </Col>
      </Row>
      <Table
        className="pt-3 whitespace-pre"
        columns={columns}
        dataSource={deliverOrders?.list || []}
        rowSelection={{
          selectedRowKeys: selectedRowIds,
          onChange: setSelectedRowIds,
        }}
        pagination={{
          showSizeChanger: false,
          total: deliverOrders?.total || 0,
          current: deliverOrders?.pageNum || 1,
          onChange: (current) => handleSetPage(current),
        }}
      />
    </Form>
  );
}

export default ISDDeliveryPage;
