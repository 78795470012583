import { ConfirmPage, ExportPage, InvoicePage } from '@pages';

const warehouseConfig = [
  {
    path: 'export',
    element: ExportPage,
    option: {
      parent: '작업장',
      label: '출고진행',
      key: 'export',
    },
  },
  {
    path: 'invoice',
    element: InvoicePage,
    option: {
      parent: '작업장',
      label: '송장 목록',
      key: 'invoice',
    },
  },
  {
    path: 'confirm',
    element: ConfirmPage,
    option: {
      parent: '작업장',
      label: 'QA / 출고 확정',
      key: 'confirm',
    },
  },
];

export default warehouseConfig;
