import { AppProvider } from '@context/AppContext';
import { LoadingProvider } from '@context/LoadingContext';
import { OverlayProvider } from '@context/OverlayContext';
import { AuthProvider } from '@context/AuthContext';

import Main from '@layout/Main';
import withAntDesign from '@hoc/withAntDesign';
import { CodeProvider } from '@context/CodeContext';

function App() {
  return (
    <AppProvider>
      <OverlayProvider>
        <LoadingProvider>
          <AuthProvider>
            <CodeProvider>
              <Main />
            </CodeProvider>
          </AuthProvider>
        </LoadingProvider>
      </OverlayProvider>
    </AppProvider>
  );
}

export default withAntDesign(App);
