import { Modal, Button, Row, Col, List } from 'antd';

import { useOverlayContext } from '@context/OverlayContext';
import ModalContainer from './base/ModalContainer';

function ModalPrintPickingListPaper({
  deliveryOrderInstructions,
  productInstructions,
}) {
  const { closeModal } = useOverlayContext();
  const onPrint = () => {
    const html = document.querySelector('html');
    const divForPrint = document.createElement('DIV');

    html.appendChild(divForPrint);
    divForPrint.innerHTML = document.querySelector(
      '#picking-lists-paper-for-print',
    ).innerHTML;
    document.body.style.display = 'none';
    window.print();
    document.body.style.display = 'block';
    divForPrint.style.display = 'none';
    closeModal();
  };

  const FOOTER = [
    <Button key="print-btn" type="primary" onClick={onPrint}>
      인쇄
    </Button>,
    <Button key="close-btn" onClick={closeModal}>
      닫기
    </Button>,
  ];

  return (
    <ModalContainer>
      <Modal
        title="출고지시서 출력 (미리보기)"
        open
        onCancel={closeModal}
        footer={FOOTER}
        maskClosable={false}
        className="w-fit"
      >
        <div id="picking-lists-paper-for-print">
          {deliveryOrderInstructions?.map(
            (deliveryOrderInstruction, paperIdx) => (
              <div className="paper" key={`picking-list-paper-${paperIdx}`}>
                <List>
                  <List.Item>
                    배송요청번호(ID) :{' '}
                    {deliveryOrderInstruction.deliveryOrderId}
                  </List.Item>
                  <List.Item>
                    주문번호(ID) : {deliveryOrderInstruction.orderNo}
                  </List.Item>
                  <List.Item>
                    총 품목 개수 : {deliveryOrderInstruction.totalCount}개
                  </List.Item>
                  <span className="block mt-5 ml-3 text-lg font-semibold">
                    품목 목록
                  </span>
                  <List
                    className="mt-2 mb-5"
                    bordered
                    dataSource={deliveryOrderInstruction.deliveryOrderLines}
                    rowKey={(deliveryOrderLine) =>
                      `paper-${paperIdx}-${deliveryOrderLine.productId}`
                    }
                    renderItem={(deliveryOrderLine, idx) => (
                      <List.Item>
                        <Row className="w-full" justify="space-between">
                          <Col span={20} push={1}>
                            {idx + 1}. {deliveryOrderLine.productName}
                          </Col>
                          <Col span={4} pull={1}>
                            {deliveryOrderLine.count}개
                          </Col>
                        </Row>
                      </List.Item>
                    )}
                  />
                </List>
                <Row className="border-solid border-[1px]">
                  <Col
                    span={12}
                    className="h-[70px] border-solid border-0 border-r-[1px]"
                  >
                    <span className="block mx-3 my-2">특이사항</span>
                  </Col>
                  <Col span={12} className="h-[70px]">
                    <span className="block mx-3 my-2">최종확인</span>
                  </Col>
                </Row>
              </div>
            ),
          )}
          <div className="paper">
            <span className="block my-5 ml-7 text-lg font-semibold">
              품목별 개수
            </span>
            <List
              dataSource={productInstructions}
              rowKey={(productInstruction) =>
                `total-${productInstruction.productId}`
              }
              renderItem={(productInstruction) => (
                <List.Item>
                  <Row className="w-full" justify="space-between">
                    <Col span={20}>{productInstruction.productName}</Col>
                    <Col span={4}>{productInstruction.count}개</Col>
                  </Row>
                </List.Item>
              )}
            />
          </div>
        </div>
      </Modal>
    </ModalContainer>
  );
}

export default ModalPrintPickingListPaper;
