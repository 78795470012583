import { Col, Divider, Form, Row, Table } from 'antd';

import { FormDatePicker, FormInput, FormSearchGroup } from '@components/form';
import { Search } from '@components/ui';

function InventoryPage() {
  const [form] = Form.useForm();

  const handleSubmit = (formValue) => {
    console.log(formValue);
  };

  const INVENTORY_SEARCH_FORM = [
    [
      <Search.Item>
        <FormDatePicker label="기준 일자" name="createdDate" />
      </Search.Item>,
      <FormInput
        placeholder="상품명을 입력하세요"
        label="상품명"
        name="productName"
      />,
      <FormInput
        placeholder="상품 ID를 입력하세요"
        label="상품 ID"
        name="productId"
      />,
    ],
  ];

  return (
    <Form form={form} onFinish={handleSubmit}>
      <Search>
        <FormSearchGroup id="isd-delivery" forms={INVENTORY_SEARCH_FORM} />
      </Search>
      <Divider className="mb-10" />
      <Row justify="space-between" align="bottom">
        <Col className="font-semibold">
          총&nbsp;
          <span className="text-secondary">0</span>개
        </Col>
      </Row>
      <Table
        className="pt-3 whitespace-pre"
        // columns={columns}
        // dataSource={dataSource}
        // pagination={tableProps?.pagination ?? false}
      />
    </Form>
  );
}
export default InventoryPage;
