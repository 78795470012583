import { ConfirmPage, InventoryPage, InventoryRegisterPage } from '@pages';

const inventoryConfig = [
  {
    path: 'inventory-search',
    element: InventoryPage,
    option: {
      parent: '재고',
      label: '재고 조회',
      key: 'inventory-search',
    },
  },
  {
    path: 'inventory-register',
    element: InventoryRegisterPage,
    option: {
      parent: '재고',
      label: '재고 등록',
      key: 'inventory-register',
    },
  },
];

export default inventoryConfig;
