import React, { useCallback } from 'react';
import { Button, message } from 'antd';

import { usePODRetryCompress } from '@api/delivery-order';

function PODRetryCompressButton({ reFetch, deliveryOrderId }) {
  const requestRetryCompress = usePODRetryCompress(reFetch);

  const handleRetryCompress = useCallback(async () => {
    message.info(
      '압축 작업을 시작 하겠습니다. 작업은 최대 10분 정도 소요됩니다.',
    );
    await requestRetryCompress(deliveryOrderId);
  }, [deliveryOrderId, requestRetryCompress]);

  return (
    <Button type="primary" onClick={handleRetryCompress}>
      재압축
    </Button>
  );
}

export default PODRetryCompressButton;
