import { Modal, Button } from 'antd';

import { useOverlayContext } from '@context/OverlayContext';
import ModalContainer from './base/ModalContainer';

function ModalStartDeliveryProcess({ deliveryStart, deliveryOrderIds }) {
  const { closeModal } = useOverlayContext();
  const route = async () => {
    await deliveryStart(deliveryOrderIds);
    closeModal();
  };
  return (
    <ModalContainer>
      <Modal
        title="출고 진행"
        open
        onCancel={closeModal}
        footer={[
          <Button type="primary" onClick={route} key="start">
            출고 진행
          </Button>,
          <Button onClick={closeModal} key="cancel">
            취소
          </Button>,
        ]}
        maskClosable={false}
      >
        <h5 className="my-5 mx-3">
          총 <span className="text-secondary">{deliveryOrderIds.length}건</span>
          을 <span className="text-secondary">출고 진행</span> 하시겠습니까?{' '}
        </h5>
      </Modal>
    </ModalContainer>
  );
}

export default ModalStartDeliveryProcess;
