import React, { useCallback } from 'react';
import { Button } from 'antd';
import fileDownload from 'js-file-download';

import { urlToBlob } from '@helper/fileHelper';

function PODPrintInfoDownloadButton({ disabled, deliveryOrderId }) {
  /**
   * 다운로드
   */
  const handleDownload = useCallback(async () => {
    const res = await urlToBlob(
      `/external/v1/delivery-orders/${deliveryOrderId}/download`,
    );
    fileDownload(res, `${deliveryOrderId}_info.txt`);
  }, [deliveryOrderId]);

  return (
    <Button type="primary" disabled={disabled} onClick={handleDownload}>
      다운로드
    </Button>
  );
}

export default PODPrintInfoDownloadButton;
