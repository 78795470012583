import React from 'react';
import { Tag } from 'antd';
import { assignIn } from 'lodash';

import useCodeStore, { CODE_KEY } from '@store/useCodeStore';

const DELIVERY_STATUS_TAG_COLORS = Object.freeze({
  POD01001: 'gray',
  POD01002: 'processing',
  POD01003: 'cyan',
  POD01004: 'success',
  POD01999: 'error',
  ISD01001: 'gray',
  ISD01002: 'processing',
  ISD01003: 'cyan',
  ISD01004: 'cyan',
  ISD01005: 'success',
  ISD01006: 'processing',
  ISD01999: 'error',
});
function TagDeliveryStatus({ deliveryStatus, ...restProps }) {
  const code = useCodeStore((state) => state.code);
  const deliveryStatusObj = assignIn(
    {},
    code[CODE_KEY.POD_DELIVERY_STATUS],
    code[CODE_KEY.ISD_DELIVERY_STATUS],
  );
  return (
    <Tag color={DELIVERY_STATUS_TAG_COLORS[deliveryStatus]} {...restProps}>
      {deliveryStatusObj[deliveryStatus]?.label ?? ''}
    </Tag>
  );
}
export default TagDeliveryStatus;
