import React, { useEffect } from 'react';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { Form } from 'antd';

const FormItemForSelect = ({
  name,
  label,
  initialValue,
  children,
  required = false,
}) => {
  const formInstance = useFormInstance();
  useEffect(() => {
    if (Array.isArray(initialValue)) {
      formInstance.setFieldValue(name, initialValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Form.Item
      colon={false}
      label={label}
      name={name}
      initialValue={initialValue}
      rules={required ? [{ required, message: '필수 입력 입니다.' }] : []}
    >
      {children}
    </Form.Item>
  );
};

export default FormItemForSelect;
