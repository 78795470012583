import { createContext, useCallback, useContext, useState } from 'react';
import { useMount } from 'ahooks';
import { useNavigate } from 'react-router-dom';

import { useLogin, useToken } from '@api/account';
import storage from '@utils/Storage';

const AuthContext = createContext();

function AuthProvider({ children }) {
  const navigate = useNavigate();

  const [isSendToken, setSendToken] = useState(false);

  const requestLogin = useLogin();
  const requestToken = useToken();

  const sendToken = ({ userId, userPw }) => {
    requestToken({ userId, userPw }, { onSuccess: () => setSendToken(true) });
  };

  const login = useCallback(
    (formValue) => {
      requestLogin(formValue, {
        onSuccess: () => {
          navigate('/isd');
          // 로그인 페이지 원래대로
          setSendToken(false);
        },
      });
    },
    [navigate, requestLogin],
  );

  const logout = useCallback(() => {
    storage.clearToken();
    navigate('/login');
  }, [navigate]);

  return (
    <AuthContext.Provider value={{ login, logout, isSendToken, sendToken }}>
      {children}
    </AuthContext.Provider>
  );
}

function useAuthContext() {
  return useContext(AuthContext);
}

export { AuthProvider, useAuthContext };
