import React from 'react';
import { Layout } from 'antd';
import { useNavigate } from 'react-router-dom';

import { LOGI_TEXT_LOGO } from '@assets';

function Header({ children }) {
  const navigate = useNavigate();

  return (
    <Layout.Header className="p-0 sticky top-0 z-[1000] h-fit leading-none border-b-2 border-slate-100">
      <div className="bg-white min-w-[1500px] w-full">
        <div className="w-full flex flex-row leading-[48px] py-1 mr-[250px]">
          <div className="w-[200px] min-w-[200px]">
            <div className="w-full h-full flex items-center justify-center">
              <img
                className="display-block cursor-pointer"
                src={LOGI_TEXT_LOGO}
                alt="로고"
                onClick={() => navigate('/isd')}
                role="presentation"
              />
            </div>
          </div>
          <div className="flex-1">{children}</div>
        </div>
      </div>
    </Layout.Header>
  );
}

export default Header;
