import { Modal, Button, Descriptions, Table } from 'antd';

import { useOverlayContext } from '@context/OverlayContext';
import ModalContainer from './base/ModalContainer';

function ModalOrderDetail({ id, orderNo, dataSource }) {
  const { closeModal } = useOverlayContext();

  return (
    <ModalContainer>
      <Modal
        title="배송주문 상세정보"
        footer={[
          <Button key="close" onClick={closeModal}>
            닫기
          </Button>,
        ]}
        width="60%"
        onCancel={closeModal}
        open
      >
        <Descriptions bordered className="my-5">
          <Descriptions.Item
            label="배송요청번호(ID)"
            labelStyle={{ width: '20%' }}
            span={3}
          >
            <div className="w-fit">{id}</div>
          </Descriptions.Item>
          <Descriptions.Item
            label="주문번호(ID)"
            labelStyle={{ width: '20%' }}
            span={3}
          >
            {orderNo}
          </Descriptions.Item>
        </Descriptions>
        <Table
          rowKey="productId"
          dataSource={dataSource}
          columns={[
            { title: '상품 ID', dataIndex: 'productId', width: 200 },
            { title: '상품명', dataIndex: 'productName' },
            { title: '개수', dataIndex: 'count', width: 80 },
          ]}
          pagination={false}
        />
      </Modal>
    </ModalContainer>
  );
}

export default ModalOrderDetail;
