import React from 'react';
import { message } from 'antd';
import { useGetDeliveryOrderStatusHistories } from '@api/delivery-order';
import { useOverlayContext } from '@context/OverlayContext';
import { ModalDeliveryStatusHistory } from '@components/overlay';

function DeliveryStatusHistoryButton({ deliveryOrderId }) {
  const { refetch: refetchDeliveryOrderStatusHistories } =
    useGetDeliveryOrderStatusHistories(deliveryOrderId);
  const { openModal } = useOverlayContext();

  const openModalDeliveryStatusHistory = async () => {
    try {
      const { data } = await refetchDeliveryOrderStatusHistories();
      if (!data) {
        message.error('배송상태 변경이력을 조회할 수 없습니다.');
        return;
      }
      openModal(ModalDeliveryStatusHistory, { data });
    } catch (e) {
      message.error('배송상태 변경이력을 조회할 수 없습니다.');
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a onClick={openModalDeliveryStatusHistory} role="presentation">
      보기
    </a>
  );
}

export default DeliveryStatusHistoryButton;
