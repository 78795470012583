import { useLocation } from 'react-router-dom';
import { Breadcrumb as DefaultBradcrumb, Col, Row } from 'antd';
import { HomeOutlined } from '@ant-design/icons';

import privateRouteConfig from '@config/privateRouteConfig';

function Breadcrumb() {
  const { pathname } = useLocation();
  const [, url] = pathname.split('/');

  const target = privateRouteConfig.find((item) => item.path === url);

  return (
    <Row gutter={[40, 0]} className="items-center">
      <Col>
        <h6>{target?.option?.label}</h6>
      </Col>
      <Col>
        <DefaultBradcrumb
          items={[
            {
              title: <HomeOutlined />,
            },
            {
              title: target?.option?.parent,
            },
            {
              title: target?.option?.label,
            },
          ]}
        />
      </Col>
    </Row>
  );
}

export default Breadcrumb;
