import React from 'react';
import { Button, message } from 'antd';

import { useOverlayContext } from '@context/OverlayContext';
import { ModalStartDeliveryProcess } from '@components/overlay';
import { useDeliveryStart } from '@api/delivery-order';

function StartDeliveryProcessButton({ reFetch, deliveryOrderIds }) {
  const { openModal } = useOverlayContext();

  const requestDeliveryStart = useDeliveryStart();
  const deliveryStart = async (ids) => {
    await requestDeliveryStart(ids);
    await reFetch();
    message.success('출고 처리 하였습니다.');
  };

  const openModalStartDeliveryProcess = async () => {
    try {
      openModal(ModalStartDeliveryProcess, {
        deliveryStart,
        deliveryOrderIds,
      });
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <Button
      type="primary"
      onClick={openModalStartDeliveryProcess}
      disabled={!deliveryOrderIds.length}
    >
      출고 진행
    </Button>
  );
}

export default StartDeliveryProcessButton;
