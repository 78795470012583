import { ISDDeliveryPage, PODDeliveryPage } from '@pages';

const deliveryConfig = [
  {
    path: 'isd',
    element: ISDDeliveryPage,
    option: {
      parent: '배송주문',
      label: 'ISD 배송주문',
      key: 'isd',
    },
  },
  {
    path: 'pod',
    element: PODDeliveryPage,
    option: {
      parent: '배송주문',
      label: 'POD 배송주문',
      key: 'pod',
    },
  },
];

export default deliveryConfig;
