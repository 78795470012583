import { LogoutOutlined } from '@ant-design/icons';
import { useAuthContext } from '@context/AuthContext';

function Gnb() {
  const { logout } = useAuthContext();

  const handleLogout = () => {
    logout();
  };
  return (
    <div className="flex flex-1 h-12 align-middle">
      <div className="mr-[50px] ml-auto ">
        <LogoutOutlined
          onClick={handleLogout}
          className="text-2xl text-secondary"
        />
      </div>
    </div>
  );
}

export default Gnb;
