import React from 'react';
import { Tooltip, message } from 'antd';

import { ModalOrderDetail } from '@components/overlay';
import { useOverlayContext } from '@context/OverlayContext';
import { useGetDeliveryOrderLines } from '@api/delivery-order';
import { onCopyToClipboard } from '@helper/uiHelper';

function DeliveryOrderId({ deliveryOrder }) {
  const { refetch: refetchDeliveryOrderLines } = useGetDeliveryOrderLines(
    deliveryOrder?.id,
  );
  const { openModal } = useOverlayContext();

  const handleCopyID = () => {
    onCopyToClipboard(deliveryOrder.id);
    message.success('ID가 클립보드에 복사 되었습니다');
  };

  const openModalOrderDetail = async () => {
    try {
      const data = await refetchDeliveryOrderLines();
      openModal(ModalOrderDetail, {
        id: deliveryOrder.id,
        orderNo: deliveryOrder.orderNo,
        dataSource: data?.data || [],
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Tooltip
      title={
        <div
          role="presentation"
          onClick={handleCopyID}
          className="hover:cursor-[pointer]"
        >
          {deliveryOrder.id}
        </div>
      }
      placement="topLeft"
    >
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a onClick={openModalOrderDetail} role="presentation">
        {deliveryOrder.id}
      </a>
    </Tooltip>
  );
}

export default DeliveryOrderId;
