import { createContext, useCallback, useContext, useState } from 'react';

import { App } from 'antd';

const OverlayContext = createContext();

function OverlayProvider({ children }) {
  const [modal, setModal] = useState();
  const { modal: modalAlert } = App.useApp();

  const openAlert = useCallback(
    (message) => {
      modalAlert.error({
        content: message,
        footer: null,
        closable: true,
      });
    },
    [modalAlert],
  );

  const openModal = useCallback((Component, props = {}) => {
    setModal({ Component, props });
  }, []);

  const closeModal = useCallback(() => {
    setModal(undefined);
  }, []);

  const renderModal = useCallback(() => {
    const { Component, props } = modal;
    return <Component {...props} />;
  }, [modal]);
  return (
    <OverlayContext.Provider
      value={{
        openAlert,
        openModal,
        closeModal,
      }}
    >
      {children}
      {modal && renderModal()}
    </OverlayContext.Provider>
  );
}

function useOverlayContext() {
  return useContext(OverlayContext);
}

export { OverlayProvider, useOverlayContext };
